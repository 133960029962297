// import Vue from 'vue';
import { createStore } from "vuex";
import JwtService from "@/common/jwt.service";
import voucher from "./voucher";
import plus from "./plus"
import credits from "./credits"
import axios from "axios";

function setAPIHeader() {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${JwtService.getToken()}`;
}
const myCreateStore = () =>
  createStore({
    modules: {
      voucher,
      plus,
      credits,
    },
    state() {
      return {
        isAuthenticated: !!JwtService.getToken(),
        users: [],
        admins: [],
        translationSources: [],
        translationTargets: [],
        companies: [],
        loggedInUser: Object.create(null),
        user: Object.create(null),
        notificationMessages: [],
        loaderOn: false,
        count: 0,
      };
    },
    mutations: {
      setUsers(state, payload) {
        state.users = payload;
      },
      setAdmins(state, payload) {
        state.admins = payload;
      },
      setTranslationSources(state, payload) {
        state.translationSources = payload;
      },
      setTranslationTargets(state, payload) {
        state.translationTargets = payload;
      },
      setCompanies(state, payload) {
        state.companies = payload;
      },
      setAuth(state, token) {
        state.isAuthenticated = true;
        JwtService.saveToken(token);
        if (JwtService.getToken()) {
          setAPIHeader();
        }
      },
      setLoggedInUser(state, user) {
        state.loggedInUser = user;
      },
      purgeAuth(state) {
        state.isAuthenticated = false;
        state.loggedInUser = {};
        JwtService.destroyToken();
        JwtService.destroySession();
      },
      pushNotificationMessage(state, message) {
        if (!state.notificationMessages) state.notificationMessages = [];
        state.notificationMessages.push(message);
      },
      removeNotificationMessage(state) {
        state.notificationMessages.pop();
      },
      toggleLoader(state, loaderState) {
        state.loaderOn = loaderState;
      },
    },
    actions: {
      login(context, payload) {
        return axios
          .post("/login", payload)
          .then((response) => {
            context.commit("setAuth", response.data.access_token);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      checkAuth(context) {
        if (JwtService.getToken()) {
          setAPIHeader();
          return axios
            .get("/user/me")
            .then((response) => {
              context.commit("setLoggedInUser", response.data.data);
              return response;
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        } else {
          context.commit("purgeAuth");
          return Promise.resolve(false);
        }
      },
      updateMe(context) {
        return axios
          .get("/user/me")
          .then((response) => {
            context.commit("setLoggedInUser", response.data.data);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          })
      },
      logout(context) {
        context.commit("purgeAuth");
      },
      getAllUsers(context, queryObject = {}) {
        const query = new URLSearchParams(queryObject).toString();
        return axios
          .get(`/user${query ? "?" + query : ""}`)
          .then((response) => {
            const users = response.data.data;
            context.commit("setUsers", users);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      exportAllUsers(context, queryObject = {}) {
        const query = new URLSearchParams(queryObject).toString();
        return axios
          .get(`/user/export${query ? "?" + query : ""}`, { responseType: 'blob' })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      addUser(context, payload) {
        return axios
          .post("/user", payload)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      updateUser(context, payload) {
        const { id } = payload;
        delete payload.id;
        return axios
          .put("/user/" + id, payload)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      getAllAdmins(context, queryObject = {}) {
        const query = new URLSearchParams(queryObject).toString();
        return axios
          .get(`/user?role=admin${query ? "&" + query : ""}`)
          .then((response) => {
            let admins = response.data.data;
            context.commit("setAdmins", admins);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      getAllTranslationSources(context, queryObject = {}) {
        const query = new URLSearchParams(queryObject).toString();
        return axios
          .get(`/translation-source?${query ? query : ""}`)
          .then((response) => {
            let sources = response.data.data;
            context.commit("setTranslationSources", sources);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      getAllTranslationTargets(context, queryObject = {}) {
        const query = new URLSearchParams(queryObject).toString();
        return axios
          .get(`/translation-target?${query}`)
          .then((response) => {
            let targets = response.data.data;
            context.commit("setTranslationTargets", targets);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      updateOrCreateTranslationTarget(context, payload) {
        return axios
          .post("/translation-target", payload)
          .then((response) => {
            const upd = context.getters.getAllTranslationTargets.map((t) => {
              const f = payload.find((d) => {
                return parseInt(d.source_id) === parseInt(t.source_id);
              });
              return f ? { ...t, text: f.text } : t;
            });
            context.commit("setTranslationTargets", upd);

            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      getAllCompanies(context) {
        return axios
          .get("/all-companies")
          .then((response) => {
            let admins = response.data.data;
            context.commit("setCompanies", admins);
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      deleteUser(context, payload) {
        return axios
          .delete("/user/" + payload.id, {
            data: { role: payload.accountType, new_user_id: payload.newUserId },
          })
          .then((response) => {
            console.log(
              "%cindex.js line:213 response",
              "color: #007acc;",
              response
            );
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      deleteTranslationSource(context, payload) {
        return axios
          .delete("/translation-source/" + payload.id)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      togglePublic(context, payload) {
        return axios
          .put("/translation-source/" + payload.id)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      getUser(context, id) {
        return axios
          .get("/user/" + id)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      pushNotificationMessage(context, message) {
        context.commit("pushNotificationMessage", message);
      },
      removeNotificationMessage(context, payload) {
        context.commit("removeNotificationMessage", payload);
      },
      getUserStats(context, userId) {
        return axios
          .get("/user-stats/" + userId)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      getUsersCreatedByAdmin(context, adminId) {
        return axios
          .get(`/user?createdBy=${adminId}`)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      saveSession(context, session) {
        return JwtService.saveSession(session);
      },
      checkSession({ commit, dispatch }) {
        if (JwtService.isSessionOut()) {
          commit("purgeAuth");
        } else {
          dispatch("saveSession", new Date());
        }
      },
      exportCompanyContacts(context, queryObject = {}) {
        const query = new URLSearchParams(queryObject).toString();
        return axios
          .get(`/companycontacts/export${query ? "?" + query : ""}`, { responseType: 'blob' })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      },
      exportKnownFrom(context, { year, period }) {
        const queryObject = { year, period };
        const query = new URLSearchParams(queryObject).toString();
        return axios
            .get(`/user/known-from/export${query ? "?" + query : ""}`)
            .then((response) => {
              return response;
            })
            .catch((error) => {
              return Promise.reject(error);
            });
      },
      impersonateUser(context, payload) {
        return axios
            .post("/impersonate-user/" + payload.id, {
              data: { role: payload.accountType },
            })
            .then((response) => {
              return response;
            })
            .catch((error) => {
              return Promise.reject(error);
            });
      },
    },
    getters: {
      getAllUsers(state) {
        return state.users;
      },
      getAllAdmins(state) {
        return state.admins;
      },
      getAllTranslationSources(state) {
        return state.translationSources;
      },
      getAllTranslationTargets(state) {
        return state.translationTargets;
      },
      getAllCompanies(state) {
        return state.companies;
      },
      isAuthenticated(state) {
        return state.isAuthenticated;
      },
      getLoggedInUser(state) {
        return state.loggedInUser;
      },
      isSuperAdmin(state) {
        return state.loggedInUser && state.loggedInUser.isSuperAdmin;
      },
      isAdmin(state) {
        return state.loggedInUser && state.loggedInUser.isAdmin;
      },
      isLicense(state) {
        return state.loggedInUser && state.loggedInUser.isLicense;
      },
      getEmailPart(state) {
        if (state.loggedInUser && state.loggedInUser.email) {
          const domainRegex = /@([\w-]+)\./;
          const match = state.loggedInUser.email.match(domainRegex);
          return match ? match[1] : null; // Return the domain part if found, otherwise return null
        }
      },
      getNotificationMessages(state) {
        console.log(
          "%cindex.js line:313 getNotif",
          "color: #007acc;",
          state.notificationMessages
        );
        return state.notificationMessages;
      },
      getLoaderOn(state) {
        return state.loaderOn;
      },
      isInPlusMode: (state) => state.loggedInUser && state.loggedInUser.plus,
      getPlusCreditsBalance: (state, getters) => {
        if (!getters.isInPlusMode || !state.loggedInUser.plus.credits || !state.loggedInUser.plus.credits.balance) {
          return 0;
        }
        return state.loggedInUser.plus.credits.balance;
      },
      isPlusManager: (state, getters) => {
        if (!getters.isInPlusMode || !state.loggedInUser.plus.manager) {
          return false;
        }
        return state.loggedInUser.plus.manager;
      }
    },
  });

export default myCreateStore;
